<template>
    <dropdown
        class="sidebar__menu-dropdown menu-dropdown menu-dropdown--narrow"
        active-class="menu-dropdown--opened"
        v-if="isLoaded"
        close-outside
    >
        <template v-slot:header>
            <company
                :domain="currentDomain"
                current
            />
        </template>
        <template v-slot:body>
            <div class="menu-dropdown__content">
                <companies/>
                <balance v-if="current_user.is_admin && !isBox"/>
                <user/>
            </div>
        </template>
    </dropdown>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'HeaderDropdown',
        components: {
            Companies: () => import('./companies/Companies'),
            Company: () => import('./companies/Company'),
            Balance: () => import('./balance/Balance'),
            User: () => import('./user/User'),
            Dropdown: () => import('@/components/Dropdown')
        },
        data() {
            return {
                isOpened: false,
                isLoaded: false,
                isBox: false
            }
        },
        computed: {
            ...mapGetters('sidebar', [
                'currentDomain'
            ]),
            ...mapGetters('default_data', [
                'current_user'
            ])
        },

        async mounted() {
            this.isLoaded = true;
        },

        async created() {
            this.isBox = process.env.VUE_APP_BOX_VERSION;
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    $top: $sidebar-padding-top;
    $left: $sidebar-padding-left;
    $width: $sidebar-width;

    .menu-dropdown {
        display: flex;
        flex-direction: column;
        border-radius: 0 0 5px 5px;
        &__company {
            &:hover {
                background-color: var(--company-hover-bg);
            }

            .company {
                &__name {
                    color: var(--company-name-color);
                }
            }
        }

        &--opened {
            z-index: 101;
            position: absolute;
            background-color: var(--dropdown-opened-bg);
            @media (min-width: 600px) {
                top: $top;
                right: calc(#{$left} - 3px);
                left: $left;
                position: absolute;
            }

            @media (max-width: 1000px) {
                top: $sidebar-padding-left;
                left: $sidebar-padding-top;
                right: $sidebar-padding-top;
            }

            .menu-dropdown {
                &__company {
                    &:hover {
                        background-color: var(--dropdown-company-hover-bg);
                    }
                    svg {
                        transform: rotate(180deg);
                        path {
                            stroke: #000;
                        }
                    }
                }
            }

            .company__name {
                color: var(--dropdown-company-name-color)
            }

            &.menu-dropdown--narrow {
                .dropdown__header {
                    width: 100%;
                }
            }
        }

        &__companies {
            display: flex;
            flex-direction: column;
        }
    }
</style>
